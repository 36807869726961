import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Link from 'react-router/lib/Link';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import _isEmpty from 'lodash/isEmpty';
import { findComponent, loadCDCSDK, redirectTo } from '../../../../common/Utils/Utils';
import {
    getLocalization,
    getLogoutReason,
    setLogoutReason,
} from '../../../../common/UserManager/UserManager';
import { clearLoginMessage, submitLogin, updateUserLocale } from '../../../../actions/LoginAction';
import { clearAllNotification, notifyError } from '../../../../actions/NotificationAction';
import { removeLoader } from '../../../../actions/LoaderAction';
import { CDC_CONTAINER_IDS, CDC_SCREEN_SET_IDS, LOGIN_INFO, LOGIN_UID, URL_PATHS } from '../../../../common/Constants';
import * as styles from '../LoginPage.css';
import { initializeOneTrustScript } from '../../../../common/OneTrust/OneTrustScript';
import { store } from '../../../../../__runtime/redux/Store';
import { clearChatForm } from '../../../../common/widgetChat/i18n/chat';
import Loader from '../../../../components/site/Loader/Loader';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

export function Login(props) {
    const callUsCountries = ["ni", "roi", "austria"];
    const localization = getLocalization();
    const {
        data,
        data: { content = {} },
        message: { error },
    } = props || {};
    const ATTR = { ...LOGIN_UID.CONTENT };
    const loginPageComponents = useSelector(state => state.loginPage.loginpage) || {};
    const notYetACustomerComponent = loginPageComponents[LOGIN_UID.NOT_YET_A_CUSTOMER];
    const getStartedComponent = loginPageComponents[LOGIN_UID.GET_STARTED];

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.documentElement.lang =
            localization[LOGIN_INFO.LANG] === LOGIN_INFO.SERBIAN_LANG
                ? LOGIN_INFO.ONETRUST_SERBIAN_LANG
                : localization[LOGIN_INFO.ONE_TRUST_ISO_CODE || LOGIN_INFO.LANG];
        initializeOneTrustScript(store.getState());

        //temporary changes for conference
        props.actions.clearAllNotification();

        props.actions.removeLoader();

        const logoutReason = getLogoutReason();
        if (logoutReason) {
            setLogoutReason(null);
            props.actions.notifyError({ message: logoutReason });
        }

        return () => {
            props.actions.clearLoginMessage();
        };
    }, []);

    const handleSubmit = (data) => {
        if (document.getElementsByClassName('cx-widget')[0]) {
            try {
                clearChatForm();
                window._genesys.widgets.bus.command('WebChat.endChat');
                window._genesys.widgets.bus.command('WebChat.close');
            } catch (error) {
                clearChatForm();
                window._genesys.widgets.bus.command('WebChat.close');
            }
        }

        if (data.response.status === 'OK' && data.response.UID) {
            props.actions.submitLogin(data.response);
        }        
    };

    useEffect(() => {
        loadCDCSDK(store.getState())
            .then(() =>
                gigya.socialize.addEventHandlers({
                    onLogin: data => {
                        try {
                            console.log(gigya);
                            if (gigya) {
                                if (data.source !== 'showScreenSet') {
                                   props.actions.submitLogin(data)
                                  }
                               if (window.location.search.includes('samlContext')) {
                                   console.log("onLogin passing through SAML context");
                                   gigya.fidm.saml.continueSSO();
                                   return Promise.reject(new Error('Stopping promise chain due to SAML context'));
                               }
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    } 
                })
            )
            .then(() => {
                gigya.accounts.showScreenSet({
                    screenSet: CDC_CONTAINER_IDS.REGISTRATION_LOGIN,
                    startScreen: CDC_SCREEN_SET_IDS.LOGIN,
                    containerID: 'login-screen-set-container',
                    onAfterSubmit: handleSubmit,
                    onSubmit: data => {
                        try {
                            buttonClickEvent(buttonClickEventConfigs.login.actions.loginForm, buttonClickEventConfigs.login.labels.loginFormLoginButton, buttonClickEventConfigs.login.categories.login);
                        } catch (ex) {
                            console.error(ex);
                        }

                        if (CDC_SCREEN_SET_IDS.PASWORDLESS_LOGIN === data.screen) {
                            props.actions.updateUserLocale(data);
                        }
                    },
                    onBeforeScreenLoad: data => {
                        setLoading(false);
                        if (data.nextScreen === CDC_SCREEN_SET_IDS.FORGOT_PASSWORD) {
                            redirectTo(URL_PATHS.FORGOT_PWD);
                            return false;
                        }
                    }
                });
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    return (
        <div className={cx('loginWrapper')} automation-id='at-login-page'>
            <h2 automation-id='at-login-title'>{content[ATTR.WELCOME]}</h2>
            <p className={cx('langLink')} automation-id='at-country-name-and-language-text'>
                {`${localization[LOGIN_INFO.COUNTRY]} & ${localization[LOGIN_INFO.LANGUAGE]}`}
                <Link to={URL_PATHS.LOCALIZATION} className={cx('langChange')} automation-id='at-change-link'>
                    {content[ATTR.COUNTRY_CHANGE]}
                </Link>
            </p>
            {loading && <div className={cx('loginLoaderWrapper')}><Loader spinner loaderClass={cx('loginLoader')} /></div>}
            <div id='login-screen-set-container' style={{ display: loading ? 'none' : 'initial' }} />
            {data[LOGIN_UID.SELF_REGISTER] && (
                <div className={cx('col12', 'md12', 'alreadyCustomer')}>
                    <p className={cx('custText', 'registerTxt')}>
                        {content[ATTR.ALREADY_CUST]}{' '}
                        <Link to={URL_PATHS.REGISTER} className={cx('phoneLink')} automation-id='at-register-link'>
                            {content[ATTR.REGISTER_CUST]}
                        </Link>
                    </p>
                </div>
            )}
            <div className={cx('col12', 'md12', 'notCustomer')} automation-id='at-login-subtext'>
                <p className={cx('custText')}>
                    {content[ATTR.ACC_CALL_US]} &nbsp;
                    <span className={cx('btnCall')}>
                        <a
                            href={`tel:${content[ATTR.PHONE_NO]}`}
                            className={cx('phoneLink')}
                            automation-id='at-phone-number-link'
                        >
                            {content[ATTR.PHONE_NO]}
                        </a>
                    </span>
                </p>
            </div>
            {callUsCountries.includes(localization?.siteId) && notYetACustomerComponent && getStartedComponent && (
                <div className={cx('col12', 'md12', 'notCustomer')} automation-id='at-login-subtext'>
                    <p className={cx('custText')}>
                        {notYetACustomerComponent?.content}
                    </p>
                    <a role="button" target='_blank' rel="noreferrer" href={getStartedComponent?.url} className={cx('phoneLink')}>
                        {getStartedComponent?.name}
                    </a>
                </div>
            )}
            {content[ATTR.EMAIL_US] && content[ATTR.EMAIL_ID] && (
                <div className={cx('col12', 'md12', 'emailCustomer')} automation-id='at-login-subtext'>
                    <p className={cx('custText')}>
                        {content[ATTR.EMAIL_US]} &nbsp;
                        <span className={cx('btnCall')}>
                            <a
                                href={`mailto:${content[ATTR.EMAIL_ID]}`}
                                className={cx('phoneLink')}
                                automation-id='at-phone-number-link'
                            >
                                {content[ATTR.EMAIL_ID]}
                            </a>
                        </span>
                    </p>
                </div>
            )}
            {data[LOGIN_UID.PARA_COMP] && (
                <div className={cx('col12', 'md12', 'callyouCustomer')} automation-id='at-login-subtext'>
                    <p
                        className={cx('custText')}
                        dangerouslySetInnerHTML={{ __html: data[LOGIN_UID.PARA_COMP].content }}
                    />
                </div>
            )}
        </div>
    );
}

Login.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    message: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

Login.defaultProps = {
    data: {},
    message: {}
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            clearLoginMessage,
            clearAllNotification,
            removeLoader,
            submitLogin,
            notifyError,
            updateUserLocale
        },
        dispatch
    )
});

export default connect(null, mapDispatchToProps)(Login);
